.shepherd-has-title .shepherd-content .shepherd-header {
 background: none;
 padding-top: 0;
 padding-bottom: 0;
 padding-left: 0.5rem;
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
  background-color: #dcc6b7;
}

.shepherd-arrow:before {
  background-color: #dcc6b7;
}

.shepherd-content {
  background-color: #dcc6b7;
  border-radius: 0.5rem;
  padding: 1rem;

  .shepherd-text {
    font-size: 0.875rem;
    color: #373435;
    padding-right: 0;
    padding-left: 0.5rem;
    white-space: pre-line;
  }

  footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0;

    .shepherd-button-primary {
      color: #373435;
      background: none;
      font-weight: 400;
      font-size: 0.75rem;
      padding: 0.5rem;
    }

    .shepherd-button-primary:hover {
      background-color: transparent;
      cursor: pointer;
      color: #373435;
      background: none;
      font-weight: 400;
      font-size: 0.75rem;
    }

    .shepherd-button-secondary {
      color: #dcc6b7;
      background-color: #373435;
      border-radius: 0.30706rem;
      padding: 0.625rem 1rem;

      font-size: 0.53738rem;
      font-weight: 700;
      letter-spacing: 0.02881rem;
    }

    .shepherd-button-secondary:hover {
      cursor: pointer;
      color: #dcc6b7 !important;
      background-color: #373435 !important;
      border-radius: 0.30706rem;
      padding: 0.625rem 1rem;

      font-size: 0.53738rem;
      font-weight: 700;
      letter-spacing: 0.02881rem;
    }

    .shepherd-button-secondary.single-button-modal {
      padding: 0.5rem 1.7rem;
      align-self: flex-end;
    }

    .shepherd-button-secondary.single-button-modal:hover {
      padding: 0.5rem 1.7rem;
    }
  }
}

.shepherd-element {
  color: #dcc6b7;
  max-width: 16.4375rem;
}






